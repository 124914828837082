var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card m-4"},[_c('div',{staticClass:"card-body p-0"},[_c('v-server-table',{ref:"companiesTable",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loading":function($event){_vm.tableLoading = true},"loaded":function($event){_vm.tableLoading = false},"row-click":_vm.onRowClicked},scopedSlots:_vm._u([{key:"id",fn:function(props){return _c('span',{},[_c('span',[_vm._v(_vm._s(props.index))])])}},{key:"Campaign",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
						name: 'ShowPromoCode',
						params: { promoCodeId: props.row.id },
					}}},[_c('div',[_c('div',[_vm._v(_vm._s(props.row.description))]),_c('div',[_c('span',[_vm._v(_vm._s(props.row.name))])])])])],1)}},{key:"Promo Code",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
						name: 'ShowPromoCode',
						params: { promoCodeId: props.row.id },
					}}},[_c('div',[_c('span',[_vm._v(_vm._s(props.row.promo_code))])])])],1)}},{key:"Max Number of Uses",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
						name: 'ShowPromoCode',
						params: { promoCodeId: props.row.id },
					}}},[_c('div',[_c('span',[_vm._v(_vm._s(props.row.max_number_of_uses))])])])],1)}},{key:"Number of Usage",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
						name: 'ShowPromoCode',
						params: { promoCodeId: props.row.id },
					}}},[_c('div',[_c('span',[_vm._v(_vm._s(props.row.number_of_usages))])])])],1)}},{key:"Expires At",fn:function(props){return _c('div',{},[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("dateformat")(props.row.expires_at ? props.row.expires_at : 'Never')))])])])}},{key:"Status",fn:function(props){return _c('div',{},[_c('div',{staticClass:"badge",class:{
						'badge-success': !props.row.has_promotion_expired,
						'badge-danger': props.row.has_promotion_expired,
					}},[_vm._v(" "+_vm._s(!props.row.has_promotion_expired ? 'Active' : 'Inactive')+" ")])])}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }