<template>
	<div class="card m-4">
		<div class="card-body p-0">
			<v-server-table
				ref="companiesTable"
				:columns="columns"
				:options="options"
				@loading="tableLoading = true"
				@loaded="tableLoading = false"
				@row-click="onRowClicked"
				>
				<span slot="id" slot-scope="props">
					<span>{{ props.index }}</span>
				</span>

				<div slot="Campaign" slot-scope="props">
					<router-link
						:to="{
							name: 'ShowPromoCode',
							params: { promoCodeId: props.row.id },
						}"
						>
						<div>
							<div>{{ props.row.description }}</div>
							<div><span>{{ props.row.name }}</span></div>
						</div>
					</router-link>
				</div>

				<div slot="Promo Code" slot-scope="props">
					<router-link
						:to="{
							name: 'ShowPromoCode',
							params: { promoCodeId: props.row.id },
						}"
						>
						<div>
							<span>{{ props.row.promo_code }}</span>
						</div>
					</router-link>
				</div>

				<div slot="Max Number of Uses" slot-scope="props">
					<router-link
						:to="{
							name: 'ShowPromoCode',
							params: { promoCodeId: props.row.id },
						}"
						>
						<div>
							<span>{{ props.row.max_number_of_uses }}</span>
						</div>
					</router-link>
				</div>

				<div slot="Number of Usage" slot-scope="props">
					<router-link
						:to="{
							name: 'ShowPromoCode',
							params: { promoCodeId: props.row.id },
						}"
						>
						<div>
							<span>{{ props.row.number_of_usages }}</span>
						</div>
					</router-link>
				</div>

				<div slot="Expires At" slot-scope="props">
					<div>
						<span>{{
							props.row.expires_at ? props.row.expires_at : 'Never' | dateformat
						}}</span>
					</div>
				</div>

				<div slot="Status" slot-scope="props">
					<div
						class="badge"
						:class="{
							'badge-success': !props.row.has_promotion_expired,
							'badge-danger': props.row.has_promotion_expired,
						}"
						>
						{{ !props.row.has_promotion_expired ? 'Active' : 'Inactive' }}
					</div>
				</div>
			</v-server-table>
		</div>
	</div>
</template>

<script>
import promoCode from '@/api/promo_codes'
import moment from 'moment'

export default {
  filters: {
    dateformat (v) {
      if (v == 'Never') return v
      return moment(v).format('YYYY-MM-DD HH:mm')
    },
    date (value) {
      if (!value) return ''
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  data () {
    const vm = this
    return {
      tableLoading: false,
      companies: null,
      columns: [
        'id',
        'Campaign',
        'Promo Code',
        'Max Number of Uses',
		'Number of Usage',
        'Expires At',
        'Status'
      ],
      options: {
        filterable: false,
        requestFunction: (data) => {
          return vm.fetchPromoCodes(data).catch(function (e) {
            
          })
        }
      }
    }
  },
  mounted () {},
  methods: {
    onRowClicked () {},
    async fetchPromoCodes (params) {
      const result = await promoCode.list({ params })
      const newResult = {
        ...result.data,
        data: result.data.models,
        count: result.data.meta.total
      }
      return {
        data: newResult
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
